@import "./shimmer.scss";
@import "./variables/variables";

.pointer {
  cursor: pointer;
}

iframe {
  display: none;
}

html {
  @include html_scroll();
}

body {
  &.modal-open,
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    padding-right: 0 !important;
  }
}

.linebreak2 {
  @include linebreak2();
}

.text-light-primary {
  color: $primary;
}

$cuColor6E: #6e6b7b;
$cuSecondary: #6e6b7b;
$cuColor88: #888888;

$mediaSizes: (
  "xs": 320px,
  "sm": 576px,
  "mid": 640px,
  "md": 767px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1500px,
);

/* fonts */
$primaryFont: "Poppins", sans-serif;
$secondaryFont: "Montserrat", sans-serif;
$primary: #3cb4b4;
$primary1: #3cb4b4;
$primary2: #8dd9d9;
$green: #28c76f;
$red: #ea5455;
$lightGreen: #3cb4b4;
$cuSecondary: $lightGreen;
$black: #000000;
$darkBlack: #0b2421;
$white: #ffffff;
$cuColor88: #888888;
$cuColor33: #333333;
$cuColorC4: #c4c4c4;
$cuColor6E: #6e6b7b;
$cuColorC1: #c1becd;
$cuColorF2: #f2f4f6;
$cuColorf8: #f8f8f8;
$cuColor85: #858585;
$cuColor87: #878787;
$packageDateColor: #9ba2a6;
$tableHead: #f3f2f7;
$tableHeadColor: #5e5873;
$selectPlaceholderColor: #b9b9c3;
$danger: #ea5455;
$warning: #ff9f43;
$darkGreen: #006464;
$offWhite: #ebffff;
$lightGray: #d9d9d9;
$gray: #858282;
$offGreen: #a7e2e2;

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

/* mixins */
@mixin all_centers {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin linebreak2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.linebreak2 {
  @include linebreak2();
}

@mixin font1218 {
  font-size: 12px;
  line-height: 18px;
}

@mixin font1421 {
  font-size: 14px;
  line-height: 21px;
}

@mixin font1624 {
  font-size: 16px;
  line-height: 24px;
}

@mixin font1826 {
  font-size: 18px;
  line-height: 26px;
}

@mixin font2232 {
  font-size: 22px;
  line-height: 32px;
}

@mixin font2037 {
  font-size: 20px;
  line-height: 37px;
}

@mixin font1626 {
  font-size: 16px;
  line-height: 26px;
}

@mixin scroll_vertical_main_hover {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary;
  }

  &:hover::-webkit-scrollbar-thumb:hover {
    border-radius: 2px;
    background-color: $primary;
  }
}

@mixin trans3 {
  transition: 0.3s ease-in-out;
}

.menu-content {
  .nav-item {
    a {
      align-items: baseline !important;
    }
  }
}

/* form-control */
.form-control {
  min-height: 45px;
  border-radius: 10px;
}

.input-group {
  .input-group-text {
    border-radius: 0 10px 10px 0;
  }
}

// .swal2-popup
.swal2-container {
  .btn {
    box-shadow: none !important;
    outline: none !important;
  }
}

.swal2-popup {
  border-radius: 10px !important;
}

/* btn  styles */
.btn {
  transition: 0.3s ease-in-out;
  padding: 14px 25px;
  @include font1421();

  &:hover {
    box-shadow: none !important;
  }

  @media (max-width: map-get($mediaSizes, "sm")) {
    padding: 12px 15px;
  }

  &.btn-primary {
    border: 1px solid $primary !important;

    &.shadows {
      box-shadow: 3px 5px 25px rgba($black, 0.12);

      &:hover {
        box-shadow: 3px 5px 25px rgba($primary, 0.12);
      }
    }

    &:hover {
      background: white !important;
      border: 1px solid $primary !important;
      color: $primary !important;
    }
  }

  &.btn-danger {
    &:hover {
      background: white !important;
      border: 1px solid $red !important;
      color: $red !important;
    }
  }

  &.btn-cu-secondary {
    background: rgba($cuSecondary, 0.12);
    border: 2px solid rgba($cuSecondary, 0.12);
    color: $cuColor33;

    &:hover {
      background: white;
      border: 2px solid rgba($cuSecondary, 0.5);
      color: $cuSecondary;
    }
  }

  &.btn-cu-gradient-primary {
    border: 0;
    background: linear-gradient(45deg, $primary1 0%, $primary2 100%);
    color: $white;
    font-weight: 500;

    &:hover {
      border: 0;

      &:not(.disabled):not(:disabled) {
        transform: translateY(0px);
      }
    }
  }

  &.btn-outline-primary {
    padding: 13.5px 25px;
    border-color: $primary !important;
    color: $primary !important;

    &:hover {
      border-color: $primary !important;
      background-color: $primary !important;
      color: $white !important;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      padding: 11px 15px;
    }
  }

  /* size */
  &.text-btn-icon {
    width: 22px;
    height: 22px;
    padding: 0;
    @include all_centers();
  }

  &.btn-icon-35 {
    width: 35px;
    height: 35px;
    padding: 0;
    @include all_centers();
  }

  &.small-btn-icon {
    @media (max-width: map-get($mediaSizes, "sm")) {
      height: 35px;
      max-width: 70px;
      margin-left: auto;
      min-height: 35px !important;
      @include all_centers();
      padding: 0 !important;
      @include font1421();
    }
  }

  &.rounded-full {
    border-radius: 50px;
  }

  &.br10 {
    border-radius: 10px;
  }

  &.minw200 {
    min-width: 200px;

    @media (max-width: map-get($mediaSizes, "sm")) {
      min-width: 120px;
    }
  }

  &.minw150 {
    min-width: 150px;
  }

  &.minw100 {
    min-width: 100px;
  }

  &.minw120 {
    min-width: 120px;
  }

  &.minh40 {
    min-height: 40px;
    padding: 7px 15px;
    @include font1218();
  }

  &.minh50 {
    min-height: 50px;

    .spinner-border {
      width: 20px;
      height: 20px;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      min-height: 45px;

      .spinner-border {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.minh45 {
    min-height: 45px;
    padding: 7px 15px;

    .spinner-border {
      width: 20px;
      height: 20px;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      min-height: 45px;

      .spinner-border {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.maxh50 {
    min-height: 50px;
    padding: 7px 15px;
    @include font1624();

    .spinner-border {
      width: 20px;
      height: 20px;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      min-height: 45px;

      .spinner-border {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.mt35 {
    margin-top: 35px;

    @media (max-width: map-get($mediaSizes, "md")) {
      margin-top: 0;
    }
  }

  .spinner-border {
    width: 18px;
    height: 18px;
  }
}

// for-error message
.error-msg {
  margin-top: 5px;
  @include font1421();
  font-weight: 400;
  color: $danger;
  display: block;
}

// Toastify
.Toastify {
  .Toastify__toast-container {
    padding: 0 !important;
    top: 20px;
    right: 15px;
    margin-left: auto;
    z-index: 9999999 !important;

    @media (max-width: map-get($mediaSizes, "md")) {
      right: 10px;
      max-width: 95%;
    }

    .Toastify__toast {
      margin: 10px 4px 0px auto !important;
      border-radius: 6px;
      padding: 0;
      box-shadow: none !important;
      min-height: 45px;
      background-color: $white !important;

      &.Toastify__toast--success {
        box-shadow: 0 6px 15px -7px rgba($lightGreen, 0.4);

        .Toastify__toast-body {
          background-color: rgba($lightGreen, 0.12) !important;

          div {
            color: $lightGreen;
          }
        }

        .Toastify__close-button {
          background-color: rgba($lightGreen, 0.12) !important;

          svg {
            fill: $lightGreen;
          }
        }
      }

      &.Toastify__toast--error {
        box-shadow: 0 6px 15px -7px rgba($danger, 0.4);

        .Toastify__toast-body {
          background-color: rgba($danger, 0.12) !important;

          div {
            color: $danger;
          }
        }

        .Toastify__close-button {
          background-color: rgba($danger, 0.12) !important;

          svg {
            fill: $danger;
          }
        }
      }

      .Toastify__toast-body {
        padding: 10px 15px;
        margin: 0;

        .Toastify__toast-icon {
          display: none;
        }

        div {
          @include font1421();
          font-weight: 600;
          font-family: $primaryFont;
        }
      }

      .Toastify__progress-bar {
        opacity: 0;
        visibility: hidden;
      }

      .Toastify__close-button {
        align-self: unset;
        margin-left: 0;
        padding: 0 10px 0 0;
        opacity: 1 !important;

        svg {
          width: 14px;
          height: 14px;
          margin-top: -3px;
        }
      }
    }
  }
}

.react-dataTable {
  .react-dataTable {
    min-height: 380px;
    @include table_vertical();
    @include table_horizontal();
  }
}

//  Select input
.select__menu-portal {
  z-index: 999999 !important;
}

.select__menu {
  border-radius: 10px !important;
  padding: 5px 0;
  z-index: 99 !important;

  .select__menu-list {
    max-height: 150px;

    .select__option {
      word-break: break-all;
      color: $primary;
      font-weight: 400;
      text-align: left;

      &.select__option--is-focused {
        background: rgba($primary, 0.2);
        font-weight: 600;
      }

      &.select__option--is-selected {
        background: $primary;
        color: $white;
        font-weight: 600;
      }
    }
  }
}

.provider-modal {
  .bg-data {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
    border-radius: 20px;
    height: 70vh;
    max-height: 600px;
    min-height: 400px;
  }

  &.modal-dialog {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($mediaSizes, "mid")) {
      max-width: 880px;
    }

    @media (min-width: map-get($mediaSizes, "sm")) {
      max-width: 880px;
    }
  }

  .heading {
    @include font2037();
    font-weight: 600;
    color: $darkGreen;
    margin-bottom: 20px;
    padding-left: 12px;
    padding-left: 8px;

    @media (max-width: map-get($mediaSizes, "lg")) {
      @include font1626();
    }
  }

  .modal-content {
    border: 0;
    border-radius: 20px !important;
    box-shadow: 0px 5px 10px rgba($black, 0.1);
  }

  .modal-body {
    position: relative;

    .inner-body {
      border-radius: 20px !important;
      overflow: hidden;
    }

    .close-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
      z-index: 9;
      height: 30px;
      width: 30px;
      color: $white;
      @include all_centers();

      &.above.diff {
        border: 2px solid $lightGreen;
        background-color: $lightGreen;
        border-radius: 50%;
        top: -15px;
        right: -15px;
      }

      svg {
        path {
          @media (max-width: map-get($mediaSizes, "lg")) {
            stroke: $white;
          }
        }
      }

      &.icon-primary {
        svg {
          path {
            stroke: $primary !important;
          }
        }
      }

      &:hover {
        transform: rotate(90deg);

        svg {
          path {
            stroke: $primary;
          }
        }
      }
    }

    .slick-dots {
      width: fit-content;
      margin: auto;
      padding: 5px 6px;
      border-radius: 10px;
      background-color: rgba($black, 0.5);
      display: flex !important;
      left: 0;
      right: 0;
      bottom: 20px;

      li {
        width: 10px;
        height: 10px;
        margin: 0px 2px;
        @include trans3();

        &.slick-active {
          width: 30px;

          button {
            opacity: 1;
          }
        }

        button {
          width: 100%;
          height: 100%;
          background-color: $white;
          border-radius: 5px;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  .main-slider,
  .second-slider {
    .slick-arrow {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      @include all_center();
      background-color: $offWhite;
      z-index: 99;
      @include trans3();

      &:focus {
        background-color: $offWhite;
      }

      &:hover {
        background-color: $primary;

        &::before {
          filter: brightness(0) invert(1);
        }
      }

      &::before {
        content: "" !important;
        height: 8px;
        width: 14px;
        background: url("../images/icons/select-icon.svg") no-repeat;
        background-size: 100%;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include trans3();
      }

      &.slick-disabled {
        display: none !important;
      }

      &.slick-prev {
        left: 10px;

        &::before {
          transform: rotate(90deg);
        }
      }

      &.slick-next {
        right: 10px;

        &::before {
          content: "";
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.modal-backdrop {
  background-color: transparent;
  opacity: 1 !important;
  backdrop-filter: blur(15px);
}

// no-data-section
.no-data-section {
  padding: 20px;
  flex-direction: column;
  border-radius: 20px;
  margin: 5px 0 0;
  width: 100%;
  @include all_center();

  p.main {
    @include font1624();
    color: $darkGreen;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
    border: 0 !important;
  }
}

// Form-group
.form-group {
  &.cu-form-group {
    margin-bottom: 20px;
  }

  .calender-icon {
    position: absolute;
    top: 35px;
    right: 12px;
    cursor: pointer;

    &:hover {
      svg {
        stroke: $primary;
      }
    }
  }
}

// common-react-select
.react-select {
  &.min150 {
    .select__control {
      min-width: 80px;
    }
  }

  &.isActive {
    .select__control {
      border: 1px solid $primary;
    }
  }

  &.multi-select {
    .select__control {
      .select__value-container {
        padding: 5px 0px !important;

        .select__multi-value {
          background-color: rgba($primary, 0.5);
          border-radius: 10px;
          margin: 6px 3px;

          .select__multi-value__label {
            color: $white;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
          }

          .select__multi-value__remove {
            &:hover {
              background-color: unset;
              color: $white;
            }

            color: $white;
          }
        }
      }
    }
  }

  .select__control {
    border: 1px solid $cuColorD8;
    border-radius: 10px;
    min-height: 45px;
    position: relative;
    box-shadow: none;
    padding: 0 1rem;

    &:hover {
      border-color: $primary;
    }

    &.select__control--menu-is-open {
      box-shadow: none;
      border-color: $primary;
    }

    .select__value-container {
      padding: 2px 0 !important;
    }

    .select__placeholder {
      color: $cuColorD8;
      @include trans3();
      @include font1421();
    }

    .select__single-value,
    .select__input-container {
      color: $primary;
      font-weight: 600;
      @include trans3();
      @include font1421();
    }
  }
}

.select__menu-portal {
  z-index: 999999 !important;
}

.select__menu {
  border-radius: 10px !important;
  padding: 5px 0;
  z-index: 99 !important;

  .select__menu-list {
    max-height: 150px;
    @include vertical_primary();

    .select__option {
      @include font1421();
      word-break: break-all;
      color: $darkGreen;
      font-weight: 600;
      @include linebreak1();

      &.select__option--is-focused {
        background: rgba($primary, 0.2);
        font-weight: 600;
      }

      &.select__option--is-selected {
        background: $primary;
        color: $white;
        font-weight: 600;
      }
    }
  }
}

// sidebar-lg
.modal {
  .sidebar-lg,
  .select-icon-modal,
  .common-header {
    .modal-header {
      min-height: 60px;

      .close-button {
        transition: 0.3s ease-in-out;
        color: $darkBlack;

        &:hover {
          transform: rotate(90deg);
          color: $danger;
        }
      }

      .modal-title {
        font-size: 18px;
        line-height: 22px;
        color: $darkBlack;
      }
    }
  }

  .modal-dialog-scrollable .modal-body {
    @include html_scroll();
  }
}

.react-dataTable {
  &.with-clickable {
    .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child {
      position: sticky;
      right: 0;
      top: 0;
      background-color: #f3f2f7;
    }

    .rdt_TableBody {
      .rdt_TableRow:nth-last-child(1),
      .rdt_TableRow:nth-last-child(2),
      .rdt_TableRow:nth-last-child(3) {
        .rdt_TableCell .action-dropdowns {
          top: unset !important;
          bottom: 5px !important;
        }
      }
    }

    .rdt_TableBody .rdt_TableRow .rdt_TableCell {
      padding: 0;
      position: unset !important;

      .padding-data {
        padding: 0.72rem 1.5rem !important;
      }

      &:last-child {
        position: sticky !important;
        right: 0;
        top: 0;
        background-color: #ffffff;
        border-bottom-color: rgba(0, 0, 0, 0.12);
      }

      .action-dropdowns {
        inset: unset !important;
        transform: unset !important;
        right: 102% !important;
        top: 10px !important;
      }
    }
  }
}

.ReactCrop .ReactCrop__image {
  cursor: unset;
  user-select: none;
  pointer-events: none;
}

.icon-primary1 svg {
  transition: 0.3s ease-in-out;

  &:hover {
    stroke: $primary !important;
  }
}

.icon-danger1 svg {
  transition: 0.3s ease-in-out;

  &:hover {
    stroke: $danger !important;
  }
}

.min-header-card {
  min-height: calc(100vh - 280px);
}

.new-tab {
  height: 100vh;
  width: 100vw;
  @include all_center();

  .new-tab-box {
    width: calc(100% - 30px);
    max-width: 500px;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 0 5px 10px rgba($black, 0.2);
    padding: 30px 20px;
    border-radius: 0px;

    p {
      color: $primary;
      font-size: 20px;
      line-height: 37px;
      margin-bottom: 0;
      text-align: center;
      font-weight: 600;
    }

    .btn {
      min-height: 45px;
      border-radius: 22.5px;
      margin-top: 30px;
    }
  }
}

.space-rating {
  span span {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.big-tip {
  .tooltip {
    padding: 0 20px !important;
  }

  .tooltip-inner {
    max-width: 500px !important;
  }
}

.nav-title {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.285rem;

  @media (max-width: map-get($mediaSizes, "xl")) {
    margin-left: 15px;
  }

  @media (max-width: map-get($mediaSizes, "lg")) {
    margin-left: 5px;
    font-size: 1.1rem;
  }

  @media (max-width: map-get($mediaSizes, "sm")) {
    font-size: 1rem;
  }
}

.custom-crop-model {
  &.modal-dialog {
    max-width: 1200px !important;
  }
}

.location-model {
  .modal-content {
    min-height: 250px;
  }
}

.floating-label-group {
  .autocomplete-dropdown-container {
    position: absolute;
    top: 45px;
    left: 0;
    width: 300px;
    background: $white;
    box-shadow: 0px 5px 10px rgba($black, 0.2);
    border-radius: 8px;
    z-index: 99;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 200px;
    color: $darkGreen !important;
    @include trans3();
    @include scroll_vertical_main_hover();

    .suggestion-item,
    .suggestion-item--active {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px !important;
      line-height: 20px !important;
      color: $darkGreen !important;
      font-size: 12px !important;
      line-height: 20px !important;

      &:first-child {
        padding-top: 15px;
      }

      span {
        color: $darkGreen !important;
        font-size: 12px !important;
        line-height: 20px !important;
        font-weight: 600;
        transition: 0.3s ease-in-out;

        &:hover {
          font-size: 12px !important;
          line-height: 20px !important;
          color: $lightGreen !important;
        }
      }
    }
  }
}

.PhoneInput {
  .flag-dropdown {
    border-radius: 8px 0 0 10px !important;
    border: 1px solid #64c3c3 !important;
    background-color: $white;
    border-right: 0 !important;
    // width: 65px;
    padding: 0 0 0 28px;

    &.open {
      border-radius: 20px 0 0 20px;
      border: 1px solid $primary !important;
    }

    .selected-flag {
      // padding: 0 !important;
      padding: 6px 0 0 6px !important;
      border-radius: 18px 0 0 18px !important;

      .flag {
        transform: scale(1.1);
      }
    }
  }

  input {
    @extend .form-control;
    margin-left: auto;
    width: calc(100% - 65px);
    //   font-family: $primaryFont;
    border-left: 0 !important;
    border-radius: 8px !important;
    padding: 10px 70px 10px 45px !important;

    &.open {
      border: 1px solid $primary !important;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      padding: 10px 35px 10px 45px !important;
    }
  }
}

.react-tel-input {
  input {
    width: 100% !important;
    border: 1px solid #64c3c3 !important;
  }

  &.country-list {
    left: 0;
    // width: 400px !important;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: $primary;
    }

    &:hover::-webkit-scrollbar {
      width: 8px;
      background-color: $primary;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0px;
      background-color: $white;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: $primary;
    }

    &:hover::-webkit-scrollbar-thumb:hover {
      background-color: $primary;
    }

    @media (max-width: map-get($mediaSizes, "sm")) {
      width: 250px !important;
    }
  }
}

.cu_multi_select__placeholder {
  color: $selectPlaceholderColor !important;
  font-weight: 500;
  @include trans3();
  @include font1421();
}

// flat-picker customizations
.flatpickr-calendar {
  .flatpickr-months .flatpickr-month {
    background: $lightGreen;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $lightGreen;
  }

  .flatpickr-weekdays {
    background: $lightGreen;
    margin-top: 0px !important;
  }

  span.flatpickr-weekday {
    background: $lightGreen;
    color: $white;
    font-weight: 400;
  }

  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.inRange {
    background: $offGreen;
    border-color: $offGreen !important;
    color: $cuColor6E;
  }
}

.flatpickr-input {
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;

  &.active {
    border-color: $offGreen !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.date-input-value {
  border-color: $darkGreen !important;
}

.flatpickr-day.selected,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background: $lightGreen !important;
}

.form-control[readonly] {
  background-color: $white !important;
}

.form-switch .form-check-input:checked,
.form-switch .form-check-input {
  background-image: url("../images/Custom/dots.svg") !important;
}

.h-400 {
  height: 320px !important;
  margin-bottom: 0 !important;
}

.clear-icon {
  position: absolute;
  top: 35px;
  right: 12px;
  cursor: pointer;
}

.menu-item {
  white-space: pre-line !important;
}

.arabic-view {
  direction: rtl;
  .public-DraftStyleDefault-ltr {
    direction: rtl;
    text-align: right;
  }
  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-right: 1.5em;
  }
  .public-DraftStyleDefault-listLTR {
    direction: rtl;
  }
  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    right: -36px;
    direction: rtl;
    text-align: right;
    margin-right: 1.5rem;
  }
  .rdw-dropdown-carettoopen {
    right: 70%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  .rdw-dropdown-carettoclose {
    right: 70%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  .rdw-link-modal {
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    background: white;
    direction: rtl;
    right: 10px;
  }
  .rdw-link-modal {
    position: absolute;
    top: 35px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 250px;
    z-index: 100;
  }
}

.english-view {
  direction: ltr;
  .rdw-link-modal {
    position: absolute;
    top: 35px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 250px;
    z-index: 100;
  }
}
.datePicker {
  .form-control:disabled, [dir] .form-control[readonly] {
      background-color: #d5cccc !important;
  }
}