$primary: #3cb4b4 !default;
$primaryLight: #78c3c3 !default;
$cuColorD8: #d8d6de;
$white: #fff !default;
$darkGreen: #006464;
$danger: #ea5455 !default; //$danger

@mixin all_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.all-center {
  @include all_center();
}
@mixin html_scroll {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $primary;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: $primary;
  }
}
@mixin table_vertical {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: $primary;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: $primary;
  }
}
@mixin table_horizontal {
  &::-webkit-scrollbar {
    height: 6px;
    background-color: $primary;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: $primary;
  }
}
@mixin vertical_primary {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $primary;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $primary;
  }
}
@mixin trans3 {
  transition: 0.3s ease-in-out;
}

@mixin linebreak1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@mixin linebreak2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
